.viewContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  .spinnerContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.filterContainer {
  margin: 22px 0px 26px 0px;

  > form {
    margin-top: 0px;
    min-height: 0px;
}
}
