.mediaView {
  width: 100%;
  max-width: 800px;
}
.input {
  background-color: #0000001f !important;
  width: 100%;
}

.button {
  margin-top: 16px !important;
  color: white;
}

.image {
  display: block;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
}
