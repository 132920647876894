.arrow {
  position: relative;
  margin: 5px;
  width: 33px;
  height: 23px;
}
.arrow-top {
  position: absolute;
  background-color: transparent;
  top: 10px;
  left: 13px;
  width: 20px;
  height: 5px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
}
.arrow-top:after {
  content: "";
  background-color: #01C1B6;;
  width: 20px;
  height: 5px;
  display: block;
  float: right;
  border-radius: 3px 5px 5px 3px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.arrow-bottom {
  position: absolute;
  background-color: transparent;
  top: 10px;
  left: 26px;
  width: 20px;
  height: 5px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
}
.arrow-bottom:after {
  content: "";
  background-color: #01C1B6;;
  width: 20px;
  height: 5px;
  display: block;
  float: right;
  border-radius: 5px 3px 3px 5px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}
.open .arrow-top:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.open .arrow-bottom:after {
  transform-origin: center center;
  transform: rotate(70deg);
}
