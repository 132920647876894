@import 'src/styles';

.ticketsMessages {
  height: 90vh;
  width: 35%;

  display: flex;
  flex-direction: column;

  border: 1px solid $clr-accent;

  .paper {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .companionInfo {
      display: flex;

      .avatar {
        margin-right: 8px;
      }
    }
  }

  .historyContainer {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 16px;
    overflow-y: scroll;
  }
}
