.cpaViewContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 20px;
  }

  .cpaOutletFilter {
    width: 200px;
  }

  .spinnerContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.checkbox {
  svg {
    color: #01c1b6;
  }

  &.disabled svg {
    color: #adb5bd;
  }
}
