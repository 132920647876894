@import 'src/styles';

.sendMessage {
  padding: 8px 16px;
  border: 1px solid $clr-accent;

  .buttonsBlock {
    display: flex;
    justify-content: space-between;

    .leftBlock {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 50px;

      .linkAttachment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
      }
    }
  }
}
