.viewContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  .spinnerContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonsContainer {
    display: flex;
    margin-top: 60px;
    justify-content: right;
  }
}

.reasonContainer {
  margin: 60px 0px 0px 0px;

  .reasonTitle {
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
  }

  .reasonDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}

.datagridContainer {
  margin-top: 60px;
}

.titleContainer {
  > span {
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    margin-right: 20px;
  }
}

.descriptionContainer {
  > span {
    margin-right: 20px;
  }
}